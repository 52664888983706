exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ansprechpartner-js": () => import("./../../../src/pages/ansprechpartner.js" /* webpackChunkName: "component---src-pages-ansprechpartner-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("./../../../src/pages/datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-fuer-aerzte-und-aerztinnen-js": () => import("./../../../src/pages/fuer-aerzte-und-aerztinnen.js" /* webpackChunkName: "component---src-pages-fuer-aerzte-und-aerztinnen-js" */),
  "component---src-pages-fuer-patienten-und-patientinnen-js": () => import("./../../../src/pages/fuer-patienten-und-patientinnen.js" /* webpackChunkName: "component---src-pages-fuer-patienten-und-patientinnen-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-karriere-js": () => import("./../../../src/pages/karriere.js" /* webpackChunkName: "component---src-pages-karriere-js" */),
  "component---src-pages-sos-kinderdorf-js": () => import("./../../../src/pages/sos-kinderdorf.js" /* webpackChunkName: "component---src-pages-sos-kinderdorf-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-pages-zertifizierungen-js": () => import("./../../../src/pages/zertifizierungen.js" /* webpackChunkName: "component---src-pages-zertifizierungen-js" */),
  "component---src-templates-subpage-js": () => import("./../../../src/templates/subpage.js" /* webpackChunkName: "component---src-templates-subpage-js" */)
}

